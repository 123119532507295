import $ from "jquery";

$(document).ready(function () {

    
    //thirdForm
        $("#thirdStepForm").validate({
            rules: {
                first_name: {
                    required: true,
                    minlength: 3,
                    maxlength: 50,
                },
                last_name: {
                    required: true,
                    minlength: 3,
                    maxlength: 50,
                },
                address: {
                    required: true,
                    maxlength: 50
                },
                email: {
                    required: true,
                    email: true
                },
                phone: {
                    required: true,
                    minlength: 10,
                    maxlength: 10
                },
                address_2: {
                    required: true,
                    maxlength: 30
                },
                paymethod: { required: true }
            },
            errorClass: "invalidClass",
            validClass: "success",
            submitHandler: function (form, event) {
                var formdata = {};
                $.each($('#thirdStepForm').serializeArray(), function (i, field) {
                    formdata[field.name] = field.value;
                });

                $("#app").append(
                    `<div class="preloaderSliderAjaxSecond">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>`
                );

                var order_id = $('#order_id').val();

                axios
                    .patch('/requests/order/' + order_id,
                        JSON.stringify(formdata),
                        { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {

                        var selectedValue = $("input[name='paymethod']:checked").val();

                        if (selectedValue == 'bank_transfer') {
                            window.location.href = "/insurance/thankyou/" + order_id;

                        }
                        if (selectedValue == 'credit_card') {
                            window.location.href = "/prosfores/credit-card-checkout?order_id=" + order_id;

                        }

                    })
                    .catch(error => {
                        console.log('Checkout post order error.');
                        var loaderCompleteT = $(".preloaderSliderAjaxSecond");
                        loaderCompleteT.removeClass('preloaderSliderAjaxSecond');
                        var loaderDotsComplete = $('.lds-ellipsis');
                        loaderDotsComplete.removeClass("lds-ellipsis");
                    })
            }
        });
    //thirdForm. =====
    //.this id step 3 ======

    //select if payment is with deposit or credit card
    $("#checkPaymentMethodCheckout input").change(function () {

        var selectedValue = $("input[name='paymethod']:checked").val();

        if (selectedValue == 'bank_transfer') {
            $(".ifBankTransfer").show();
            $(".ifCreditCard").hide();
        }
        if (selectedValue == 'credit_card') {
            $(".ifCreditCard").show();
            $(".ifBankTransfer").hide();
        }

    });
    //select if payment is with deposit or credit card


    //get banks name according to payment method to bank_Trnasfer
    $(".ifBankTransfer input").change(function () {
        var selectedValue = $("input[name='bankName']:checked").val();
    });
    //get banks name according to payment method to bank_Trnasfer

    $("#getInstalmentValue ").change(function () {

        var selectedValue = $("#getInstalmentValue option:selected").val();
        var resultAsInstalment = usePriceToInstalment / selectedValue ;

        $("#amountperinstalment").val(resultAsInstalment.toFixed(2));
    });

});
