import $ from "jquery";

$(window).on("load", function () {
    $('.lds-ellipsis div').hide();
    $('.preloaderSlider').hide();
});

$(document).ready(function () {

    if (screen.width <= 767) {
        var reverseTR = $('.reverseTR');
        $(reverseTR).removeClass('text-right');
        $(reverseTR).addClass("text-center");
    }

    $(".toggle").click(function () {
        $(this).toggleClass("on");
        $(".menu").slideToggle();
    });

    if (screen.width >= 767) {
        var menu = $('.mainMenu');
        $(menu).removeClass('menu');
    }

    // ***********************************************
    //start sync
    // ***********************************************
    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    var sync3 = $("#sync3");
    var sync4 = $("#sync4");
    var sync5 = $("#sync5");
    var slidesPerPage = 6; //globaly define number of elements per page
    var syncedSecondary = true;


    sync1.owlCarousel({
        items: 1,
        slideSpeed: 2000,
        nav: false,
        autoplay: false,
        dots: false,
        loop: true,
        margin: 0,
        responsiveRefreshRate: 200,
        navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>', '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
    }).on('changed.owl.carousel', syncPosition);


    sync2
        .on('initialized.owl.carousel', function () {
            sync2.find(".owl-item").eq(0).addClass("current");
            console.log('test');
        })
        .owlCarousel({
            items: slidesPerPage,
            dots: true,
            nav: true,
            smartSpeed: 200,
            slideSpeed: 500,
            slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
            responsiveRefreshRate: 100
        }).on('changed.owl.carousel', syncPosition2);

    sync3
        .owlCarousel({
            items: slidesPerPage,
            dots: true,
            nav: true,
            smartSpeed: 200,
            slideSpeed: 500,
            slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
            responsiveRefreshRate: 100
        });
    sync4
        .owlCarousel({
            items: slidesPerPage,
            dots: true,
            nav: true,
            smartSpeed: 200,
            slideSpeed: 500,
            slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
            responsiveRefreshRate: 100
        });
    sync5
        .owlCarousel({
            items: slidesPerPage,
            dots: true,
            nav: true,
            smartSpeed: 200,
            slideSpeed: 500,
            slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
            responsiveRefreshRate: 100
        });





    function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        //var current = el.item.index;

        //if you disable loop you have to comment this block
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - (el.item.count / 2) - .5);

        if (current < 0) {
            current = 0;
        }
        if (current > count) {
            current = 0;
        }

        //end block

        sync2
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        sync3
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var onscreen = sync3.find('.owl-item.active').length - 1;
        var start = sync3.find('.owl-item.active').first().index();
        var end = sync3.find('.owl-item.active').last().index();

        if (current > end) {
            sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
            sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }





    }

    function syncPosition2(el) {
        if (syncedSecondary) {
            var number = el.item.index;
            sync1.data('owl.carousel').to(number, 100, true);
        }
    }

    sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });

    sync3.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });

    sync4.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });
    sync5.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });


    // ***********************************************
    //end sync
    // ***********************************************


    $("#testimonials").owlCarousel({
        items: 3,
        dots: true,
        nav: true,
        responsiveClass: true,
        margin: 10,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 2,
                nav: false
            },
            1000: {
                items: 3,
                nav: true,
                dots: true,
                loop: false
            }
        }
    });
    $(".owl-prev").html('<i class="fas fa-arrow-left icon iconleft"></i>');
    $(".owl-next").html('<i class="fas fa-arrow-right icon iconright"></i>');

    //this id step 3 ======
    $("#btnInsCov").click(function () {
        $("#coverSec").slideToggle();
        changeBg();
    });

    function changeBg(e) {
        $("#btnInsCov").toggleClass("backgroungChange");
        // div.find(".analysisInsuarance").toggleClass("backgroungChange992");
    }



    //thank you page
        $("h1").hover(function () {
            // when user is hovering the h1
            $(this).addClass("animated infinite pulse");
            // we add pulse animation and to infinite
        }, function () {
            // when user no longer hover on the h1
            $(this).removeClass("animated infinite pulse");
            // we remove the pulse
        });
    //thank you page

    // Cookies + GDPR popup
    var x = getCookie('e-asfalistro-cookies');
    if (!x) {
        $("#gdrpCookie").show();
    }

    $(".cookieOk").click(function() {
        setCookie('e-asfalistro-cookies','1',30);
        var photo = document.getElementById("gdrpCookie");
        TweenLite.to(photo, 5, { left:"7000px" });
        //TweenLite.to('.cookies', 1, { right: 2000, timeScale: 4, ease: Power4.easeIn });
    });

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie() {
        var user = getCookie("username");
        if (user != "") {
            alert("Welcome again " + user);
        } else {
            user = prompt("Please enter your name:", "");
            if (user != "" && user != null) {
                setCookie("username", user, 365);
            }
        }
    }

    $('.chasm-common-form-field').change(function () {
        let company = this.value;

        $('.chasm-documents-list-item').hide();
        $("li[data-company='"+company+"']").each(function(index) {
            setTimeout(function(el) {
                el.fadeIn();
            }, index * 1000, $(this));
        });

    });

});
