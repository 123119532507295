import flatpickr from 'flatpickr';
import $ from 'jquery';
import {changeModelCarSelect} from "./index";

$(document).ready(function () {

    $(".requestLoadingEvent").hide();
    //booleans for request Forms
    var isKivismosOk = false;
    var isregNumberOximatosOk = false;
    var isvalidationCcOk = false;
    var isvalidateTKOk = false;
    var isSexOk = true;
    var isOccupationOk = false;
    var isEmailOk = true; // change that to validate
    var isManufactureYearOk = false;
    var isMobilePhoneOk = true; // change that to validate
    var inNationalityOk = false;
    var isinsuranceValueOk = true;
    var isLicenseDateOk = false;
    var isInsranceStartDateOk = false;
    //.booleans for request Forms

    //$(function () {
    //    ///FLATPICKR
    //    var flatpickrInstance = '';
    //    var addYearsToInstance = '';
    //    const flatBirthdayFunct = $(".flatBirthday").flatpickr({
    //        dateFormat: "d-m-Y",
    //        allowInput: true,
    //        onChange: function (selectedDates, dateStr, instance) {
    //            selectedDates.forEach(function (date) {
    //                flatpickrInstance = instance.selectedDates[0];
    //                alert(flatpickrInstance);

    //                // addYearsToInstance = new Date(date.setFullYear(date.getFullYear() + 18));
    //                //alert(addYearsToInstance);
    //            })
    //        }
    //    });
    //    $("#license_date").flatpickr({
    //        dateFormat: "d-m-Y",
    //        allowInput: true,
    //        onChange: function (selectedDates) {
    //            //const dateArr = selectedDates.map(date => this.formatDate(date, "d-m-Y"));
    //            $('.flatBirthday').val(selectedDates[0]);
    //            alert($('.flatBirthday').val(selectedDates[1]));

    //        }
    //    });
    //});

    ///FLATPICKR
    var startPicker = flatpickr(".flatBirthday", {
        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        dateFormat: "d/m/Y",
        allowInput: true,
        disableMobile: "true",
        onChange: function (selectedDates, dateStr, instance) {
            let dob = new Date(selectedDates[0]);
            //endPicker.set('minDate', dob.setFullYear(dob.getFullYear() + 18));
            clearLisenceYears();
            calculateLisenceYears(dob);
        },
        onReady: function (selectedDates, dateStr, instance) {
            let dob = new Date(selectedDates[0]);

            if(dob.getFullYear() > 0) {
                clearLisenceYears();
                calculateLisenceYears(dob, selected_licenseyear);
                calculateLicenseDate();
            }
        }
    });

    $(".flatBirthday").on('blur', function () {
        let date = $(this).val().split("/");
        let dob = new Date(date[2], date[1] -1, date[0]);
        let current_year = new Date().getFullYear();

        if((dob.getFullYear() + 18) > current_year) {
            $("#dob").css("border", "solid 1px red");
            clearLisenceYears();
            $( "#licenseyears" ).prop( "disabled", true );
        } else {
            $("#dob").css("border", "solid 1px #ced4da");
            calculateLisenceYears(dob);
        }
    });

    function calculateLisenceYears(dob, selected_licenseyear = null) {
        let current_year = new Date().getFullYear();
        clearLisenceYears();
        $( "#licenseyears" ).prop( "disabled", false );

        let startYear = current_year - (dob.getFullYear() + 17);

        for (let i = startYear; i >= 0; i--)
        {
            let from = i - 1;
            let to = i;
            if(from>0) {
                let selected = '';
                if(selected_licenseyear == from) {
                    selected = ' selected="selected" ';
                }
                $('#licenseyears').append( '<option value="'+from+'"'+selected+'>Από '+from+' έως '+to+' έτη</option>' );
            }
        }
        $('#licenseyears').append( '<option value="0">Λιγότερο από 1 έτος</option>' );
    }

    function clearLisenceYears() {
        $('#licenseyears')
            .empty()
            .append('<option value="">Επιλέξτε</option>')
        ;
    }

    function calculateLicenseDate() {
        //license date
        let year_diff = parseInt($('#licenseyears').val());

        //Date of Birth
        let date = $('#dob').val().split("/");

        //Current date
        let current_date = new Date();

        let new_year = current_date.getFullYear() - year_diff;

        if(
            (year_diff <= 0) ||
            (
                ((parseInt(date[2]) + 18 + year_diff) == current_date.getFullYear()) &&
                (
                    (parseInt(date[1]) > (current_date.getMonth()+1)) ||
                    ((parseInt(date[1]) == (current_date.getMonth()+1)) && (parseInt(date[0]) >= current_date.getDate()))
                )
            )
          )
        {
                //fix month
                date[1] = (current_date.getMonth() < 9) ? "0"+(current_date.getMonth()+1) : current_date.getMonth()+1;

                //get yesterday
                var d = new Date();
                d.setDate(d.getDate() - 1);

                date[0] = (d.getDate() < 9) ? "0"+d.getDate().toString() : d.getDate();

        }

        let license_date = date[0] + '/' + date[1] + '/' + new_year;
        $("#license_date").val(license_date);
    }

    $("#licenseyears").on('change', function()
    {
        calculateLicenseDate();
    });

    // var endPicker = flatpickr("#license_date", {
    //     dateFormat: "d-m-Y",
    //     allowInput: true,
    //     disableMobile: "true",
    //     maxDate: 'today'
    // });


    var startInsuranceDate =  $(".flatInsuranceStartDate").flatpickr({
        dateFormat: "d-m-Y",
        minDate: "today",
        allowInput: true,
        disableMobile: "true",
        maxDate: new Date().fp_incr(40),
        onChange: function () {
            validateInsranceStartDate();
        },
        "disable": [
            function(date) {
                let today = new Date();
                return (date.getDate() === today.getDate() && today.getHours() > 20);

            }
        ]
    });

    $("#insurance_start_date").on('change', function()
    {
        validateInsranceStartDate();
    });

    function validateInsranceStartDate() {
        let date = $('#insurance_start_date').val().split("/");
        let startdate = new Date(date[2], date[1] -1, date[0]);
        let current_date = new Date();
        current_date.setHours(0,0,0, 0);
        let correct_date = new Date();
        let current_time = new Date().getHours();

        if (current_time > 20){
            correct_date.setDate(current_date.getDate()+41);
            current_date.setDate(current_date.getDate() + 1);
        } else{
            correct_date.setDate(current_date.getDate()+40);
            current_date.setDate(current_date.getDate());
        }

        if
        (
            current_date > startdate ||
            startdate > correct_date
        ) {
            $("#insurance_start_date").css("border", "solid 1px red");
            isInsranceStartDateOk = false;
        } else {
            $("#insurance_start_date").css("border", "solid 1px #ced4da");
            isInsranceStartDateOk = true;
        }
    }
    //FLATPICKR

    //request page masks
    $('.regNumberOximatos').inputmask({
        mask: "U{2,3}-9{3,4}",
        greedy: false,
        definitions: {
            'U': {
                validator: "[A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώ]",
                casing: "upper"
            }
        }
    });
    $(".birthday").inputmask("99/99/9999", { "placeholder": "__/__/____" });
    $(".insranceStartDate").inputmask("99/99/9999", { "placeholder": "__/__/____" });
    //$(".insuranceValue").inputmask("9.999€");
    //$("#license_date").inputmask("99/99/9999", { "placeholder": "__/__/____" });
    // .request page masks

    //validate asfalizomeni aksia
    $(".insuranceValue").keyup(function () {
        validateInsuranceValue();

    });

    function validateInsuranceValue() {
        var isNumeric = $.isNumeric($(".insuranceValue").val());

        if (!isNumeric) {
            $(".insuranceValue").css("border", "solid 1px red");
            isinsuranceValueOk = false;
        }
        else {
            $(".insuranceValue").css("border", "solid 1px #ced4da");
            isinsuranceValueOk = true;
        }
    }

    //.validate asfalizomeni aksia

    //validate arithmo kikloforias
    $(".regNumberOximatos").on('change', function()
    {
        var greeklishCharactersToGreekArray = {
            A: 'Α',
            a: 'α',
            B: 'Β',
            b: 'β',
            V: 'Β',
            v: 'β',
            c: 'ψ',
            C: 'Ψ',
            G: 'Γ',
            g: 'γ',
            D: 'Δ',
            d: 'δ',
            E: 'Ε',
            e: 'ε',
            Z: 'Ζ',
            z: 'ζ',
            H: 'Η',
            h: 'η',
            U: 'Θ',
            u: 'υ',
            I: 'Ι',
            i: 'ι',
            j: 'ξ',
            J: 'Ξ',
            K: 'Κ',
            k: 'κ',
            L: 'Λ',
            l: 'λ',
            M: 'Μ',
            m: 'μ',
            N: 'Ν',
            n: 'ν',
            X: 'Χ',
            x: 'χ',
            O: 'Ο',
            o: 'ο',
            P: 'Π',
            p: 'π',
            R: 'Ρ',
            r: 'ρ',
            S: 'Σ',
            s: 'σ',
            T: 'Τ',
            t: 'τ',
            Y: 'Υ',
            y: 'υ',
            F: 'Φ',
            f: 'φ',
            W: 'Ω',
            w: 'ω'
        };
        var regNumber = $(".regNumberOximatos").val();
        regNumber = regNumber.toUpperCase();
        regNumber = regNumber.replace("-", "");

        $.each(greeklishCharactersToGreekArray, function(key, value)
        {
            // perform global march using g modifier
            regNumber = regNumber.replace(RegExp(key, 'g'), value);
        });

        $(this).val(regNumber);
        validateNumberOximatos();
    });

    function validateNumberOximatos() {
        var regNumber = $(".regNumberOximatos").val();
        if (regNumber === '' || regNumber == null) {
            isregNumberOximatosOk = false;
            $(".regNumberOximatos").css('border', 'solid 1px red');
        } else {
            isregNumberOximatosOk = true;
            $(".regNumberOximatos").css('border', 'solid 1px #ced4da');
        }

    }
    //.validate arithmo kikloforias

    // validate Έκδοση
    $("#select2-type-container").keyup(function () {
        validateKivismosCarSelect();
    });

    function validateKivismosCarSelect() {

        if(selected_usage != 83) {
            isKivismosOk = true;
            return true;
        }

        var kivismos = $(".kivismosCarSelect").val();

        if (kivismos === '' || kivismos == null) {
            $("#select2-type-container").attr("style", "border: solid 1px red !important");
            isKivismosOk = false;
        } else {
            $("#select2-type-container").attr("style", "border: solid 1px #ced4da !important");
            isKivismosOk = true;
        }
    }
    // .validate Έκδοση

    function validateTK() {
        var isNumeric = $.isNumeric($("#zip_code").val());

        if (!isNumeric) {
            $("#zip_code").css("border", "solid 1px red");
            isvalidateTKOk = false;
        }
        else {
            $("#zip_code").css("border", "solid 1px #ced4da");
            isvalidateTKOk = true;
        }
    }
    //.validate TK

    // validate nationality
    // $("#nationality").change(function () {
    //     validateNationality();
    // });
    //
    // function validateNationality() {
    //     var nationalityValue = $("#nationality").val();
    //
    //     if (nationalityValue === '' || nationalityValue == null) {
    //         inNationalityOk = false;
    //         $("#nationality").css("border", "solid 1px red");
    //     } else {
    //         inNationalityOk = true;
    //         $("#nationality").css("border", "solid 1px #ced4da");
    //     }
    // }
    // .validate nationality


    //epaggelma validation
    $("#occupation").change(function () {
        validateOccupation();
    });

    function validateOccupation() {
        var occupationValue = $("#occupation").val();

        if (occupationValue === '' || occupationValue == null) {
            isOccupationOk = false;
            $("#occupation").css("border", "solid 1px red");
        } else {
            isOccupationOk = true;
            $("#occupation").css("border", "solid 1px #ced4da");
        }
    }
    //.epaggelma validation

    //sex validation
    // $("#sex").keydown(function () {
    //     validateSex();
    // });
    // function validateSex() {
    //     var sexValue = $("#sex").val();
    //
    //     if (sexValue === '' || sexValue == null) {
    //         $("#sex").css("border", "solid 1px red");
    //         isSexOk = false;
    //     } else {
    //         isSexOk = true;
    //         $("#sex").css("border", "solid 1px #ced4da");
    //     }
    //
    // }
    //.sex validation

    $("#profile-tab").on("click", function () {
        $("#sex").val(3);
    });
    $("#home-tab").on("click", function () {
        $("#sex").val(1);
    });

    //validate license_category
    //if ($('#license_category').val() == 1 ) {
    //    $("#license_category").css("border", "solid 1px #ced4da");
    //}
    //$('#license_category').change(function () {
    //    if ($(this).val() == '2') {
    //        $("#license_category").css("border", "solid 1px #ced4da");
    //    }
    //});    //if ($('#license_category').val() == 1 ) {
    //    $("#license_category").css("border", "solid 1px #ced4da");
    //}
    //$('#license_category').change(function () {
    //    if ($(this).val() == '2') {
    //        $("#license_category").css("border", "solid 1px #ced4da");
    //    }
    //});
    //validate license_category


    //validate registration date
    $(".validationCc").keyup(function () {
        validateCc();
    });
    function validateCc() {

        if(selected_usage != 83) {
            isvalidationCcOk = true;
            return true;
        }

        var validationCubism = $(".validationCc").val();
        var isNumeric = $.isNumeric(validationCubism);

        if (!isNumeric) {
            $(".validationCc").css("border", "solid 1px red");
            isvalidationCcOk = false;
        }
        else if (validationCubism <= 399) {
            $(".validationCc").css("border", "solid 1px red");
            isvalidationCcOk = false;
        }
        else if (validationCubism >= 10001) {
            $(".validationCc").css("border", "solid 1px red");
            isvalidationCcOk = false;
        } else {
            $(".validationCc").css("border", "solid 1px #ced4da");
            isvalidationCcOk = true;
            changeModelCarSelect();
        }
    }
    //.validate registration date

    // validate manufacture year #request page
    $(".validateManufactureYear").keyup(function () {
        validateManufactureYear();
    });

    function validateManufactureYear() {

        //var validateManufyear = $(".validateManufactureYear").val();
        var carYearValue = $(".validateManufactureYear").val();
        var isNumeric = $.isNumeric($(".validateManufactureYear").val());
        var useDate = new Date();
        var todaysYear = useDate.getFullYear();

        if (isNumeric && carYearValue >= 1801 && carYearValue <= todaysYear) {
            $(".validateManufactureYear").css("border", "solid 1px #ced4da");
            isManufactureYearOk = true;
            changeModelCarSelect();
        }
        else if (!isNumeric) {
            $(".validateManufactureYear").css("border", "solid 1px red");
            isManufactureYearOk = false;
        }
        else if (carYearValue > todaysYear) {
            $(".validateManufactureYear").css("border", "solid 1px red");
            isManufactureYearOk = false;
        }
        else {
            $(".validateManufactureYear").css("border", "solid 1px red");
            isManufactureYearOk = false;
        }

    }
    // .validate manufacture year #request page

    //valiate email
    var validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    $('.validateEmail').change(function () {

        var isValid = this.value.match(validEmail),
            isInternet = this.value.match(/@.*\./);

        if (isValid) {
            $(".validateEmail ").css("border", "solid 1px #ced4da");
            isEmailOk = true;
        } else {
            $(".validateEmail ").css("border", "solid 1px red");
            isEmailOk = false;
        }
    })
    //.validate email

    //validate AFM
    $(".validateAfm").keyup(function () {
        validateAFM();
        if (validateAFM() == true) {
            $(".validateAfm").css("border", "solid 1px #ced4da");

        }
    });
    function validateAFM() {
        var afm = $(".validateAfm").val();
        afm = afm.toString();

        if (!afm.match(/^\d{9}$/) || afm === '000000000') {
            $(".validateAfm").css("border", "solid 1px red");
            return false;
        }

        var m = 1, sum = 0;
        for (var i = 7; i >= 0; i--) {
            m *= 2;
            sum += afm.charAt(i) * m;
        }
        return sum % 11 % 10 === parseInt(afm.charAt(8));

    }
    //.validate AFM

    //show "Ημερομηνία μεταβίβασης" according "Το όχημα το αγοράσατε καινούργιο;"
    $("#questionForCar input").change(function () {
        var selectedValue = $("input[name='newCarQuestion']:checked").val();

        if (selectedValue == '1') {
            $(".ifYesChecked").hide();
        }
        if (selectedValue == '0') {
            $(".ifYesChecked").show();
        }
    });

    if ($("#a28").is(':checked') != true) {
        $(".ifYesChecked").hide();
    }
    //.show "Ημερομηνία μεταβίβασης" according "Το όχημα το αγοράσατε καινούργιο;"

    //validate phone number
    $(".validatePhoneNumber").keyup(function () {
        validatePhoneNumber();
    });

    function validatePhoneNumber() {
        return true;
        var phoneNumber = $(".validatePhoneNumber").val();

        if ($(".validatePhoneNumber").val().length != 10) {
            $(".validatePhoneNumber").css("border", "solid 1px red");
            isMobilePhoneOk = false;

        } else {
            $(".validatePhoneNumber").css("border", "solid 1px #ced4da");
            isMobilePhoneOk = true;
        }
    }
    //.validate phone number

    //validate registration date --Έτος κατασκευής
    $(".registrationDate").keyup(function () {
        countregistDate();
    });
    function countregistDate() {
        var registrationDate = $(".registrationDate").val();
        var date1800 = 1800;
        var todaysYear = 2019 + 1;

        if (!$.isNumeric($(".registrationDate").val())) {
            $(".registrationDate").css("border", "solid 1px red");
        }
        else if (registrationDate <= date1800) {
            $(".registrationDate").css("border", "solid 1px red");
        }
        else if (registrationDate >= todaysYear) {
            $(".registrationDate").css("border", "solid 1px red");
        } else {
            $(".registrationDate").css("border", "solid 1px #ced4da");
        }
    }
    //.validate registration date --Έτος κατασκευής

    // clients information #request page (fusiko-nomiko prosopo)
    // $("#profile-tab").on("click", function () {
    //     $("#personButton").hide();
    //     $("#occupationButton").hide();
    // });
    // $("#home-tab").on("click", function () {
    //     $("#personButton").show();
    //     $("#occupationButton").show();
    // });
    // .clients information #request page (fusiko-nomiko prosopo)

    //request form validation on submit
    $(document).on('submit', '#requestToResult', function (e) {
        event.preventDefault();

        $('.requestLoadingEvent').show();

        validateInsuranceValue();
        validateKivismosCarSelect();
        validateTK();
        validateNumberOximatos();
        validateOccupation();
        //validateSex();
        validateCc();
        validateManufactureYear();
        validateAFM();
        validatePhoneNumber();
        validateInsranceStartDate();

        if (isMobilePhoneOk = true && isregNumberOximatosOk == true && isvalidationCcOk == true && isSexOk == true && isOccupationOk == true && isKivismosOk == true && isInsranceStartDateOk == true) {

            var form = $(this);
            var url = '/requests/offer';

            $.ajax({
                type: "POST",
                url: url,
                data: form.serialize(), // serializes the form's elements.
                beforeSend: function () {
                    $("#app").append(
                        `<div class="preloaderSliderAjaxSecond">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>`
                    );
                },
                success: function(data)
                {
                    $("#app").append(
                        `<div class="preloaderSliderAjaxSecond">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>`
                    );
                    window.location.href = "/insurance/results/"+data;
                },
                complete: function (data) {
                    var loaderCompleteT = $(".preloaderSliderAjaxSecond");
                    loaderCompleteT.removeClass('preloaderSliderAjaxSecond');
                    var loaderDotsComplete = $('.lds-ellipsis');
                    loaderDotsComplete.removeClass("lds-ellipsis");
                },
                error: function (response) {
                    $(".carInsurance").append(
                        `<div class="preloaderSliderAjaxSecond extraSliderAjax sliderAjax">
                        <div class="">
                            <h3>Παρακαλώ προσπαθήστε ξανά.</h3>
                        </div>
                    </div>`
                    );
                }
            });
        } else {

            validateInsuranceValue();
            validateKivismosCarSelect();
            validateTK();
            validateNumberOximatos();
            validateOccupation();
            //validateSex();
            validateCc();
            validateManufactureYear();
            validateAFM();
            validatePhoneNumber();

            // while (isMobilePhoneOk != true || isregNumberOximatosOk != true || isvalidationCcOk != true || isSexOk != true || isOccupationOk != true) {
            //     event.stopPropagation();
            // }
            $('.requestLoadingEvent').hide();

        }
    });
    //request form validation on submit


    if (window.location.href.indexOf("request") !== -1)
    {
        /**
         * Prepare the postcode select list.
         */
        (function()
        {
            // Create the local variables.
            var url = '/requests/postcodes';

            $('#zip_code').autocomplete({
                delay: 500,
                minLength: 3,
                source: (fromRequest, toReturn) => {
                    axios.get(url, {
                        params: {
                            search: fromRequest.term
                        }
                    })
                        .then(response => {
                            toReturn(response.data);
                        })
                        .catch(error => {
                            console.log(toReturn);
                        });
                }
            });
        })();
    }


    $("#collapseExamples").hide();
    $(".showSched").click(function () {
        $("#collapseExamples").toggle();
    });


    $("#requestToResult").validate({
        rules: {
            license_plate: {
                required: true
            },
            model: {
                required: true
            },
            construction_year: {
                required: true
            },
            cubic_capacity: {
                required: true
            },
            current_price: {
                required: true
            },
            brand: {
                required:true
            },
            // sex: {
            //     required: true
            // },
            occupation: {
                required: true
            },
            // nationality: {
            //     required: true
            // },
            dob: {
                required: true
            },
            zip_code: {
                required: true
            },
            vat: {
                required: true
            },
            // license_date: {
            //     required: true
            // },
            license_category: {
                required: true
            },
            // mobile: {
            //     required: true
            // },
            // email: {
            //     required: true
            // },
            pay_way: {
                required: true
            },
            insurance_start_date: {
                required: true
            },
            termsvalidate: {
                required:true
            }
        },
        errorClass: "invalid",
        validClass: "success",
        highlight: function(element) {
            $(element).parent().addClass("field-error");
        },
        unhighlight: function(element) {
            $(element).parent().removeClass("field-error");
        }
    });

    $('.kivismosCarSelect').on('change', '', function () {
        getInsValue();
    });

    if (window.location.href.indexOf("request") !== -1) {
        if ((selected_engine == null) && (selected_usage == 83))
        {
            // On load disable brand - year - cubic
            $('#cubic_capacity').attr('disabled', 'disabled');
            $('.modelCarSelect').attr('disabled', 'disabled');

            $('#license_plate').on('change', '', function () {
                $('#construction_year').removeAttr('disabled');
                $('#cubic_capacity').removeAttr('disabled');
                $('.modelCarSelect').removeAttr('disabled');
            });

            // On load disable engine - model - ins value 's dropdown
            $('.kivismosCarSelect').attr('disabled', 'disabled');
            $('#current_price').attr('disabled', 'disabled');
            $('#construction_year').attr('disabled', 'disabled');

            $('#cubic_capacity').on('change', '', function () {
                $('.kivismosCarSelect').removeAttr('disabled');
                $('#current_price').removeAttr('disabled');

            });
        }
    }

    function getInsValue()
    {
        let data = {
            brand_id: $( "select.markaCarSelect" ).val(),
            model_name: $( "select.modelCarSelect" ).find("option:selected").text(),
            engine_id: $( "select.kivismosCarSelect" ).val(),
            engine_name: $( "select.kivismosCarSelect" ).find("option:selected").text(),
            construction_year: $( "#construction_year" ).val(),
            cubic_capacity: $( "#cubic_capacity" ).val()
        };

        return axios
            .post('/requests/insvalue', data,
                {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                let text = 'Η Ασφαλιζόμενη Αξία θα πρέπει να είναι';

                if(response.data['minPrice'] > 0) {
                    $( "#current_price" ).attr('min', response.data['minPrice']);

                    text += ' μεγαλύτερη από ' + response.data['minPrice'].toLocaleString('el-GR') + ' €';
                }

                if((response.data['minPrice'] > 0) && (response.data['maxPrice'] > 0)) {
                    text += ' και ';
                }

                if(response.data['maxPrice'] > 0) {
                    $( "#current_price" ).attr('max', response.data['maxPrice']);
                    text += ' μικρότερη από ' + response.data['maxPrice'].toLocaleString('el-GR') + ' €.';
                }

                if((response.data['minPrice'] > 0) || (response.data['maxPrice'] > 0)) {
                    $('#ins-value-text').text(text);
                }

            })
    }

    $('.markaCarSelect').on('change', '', function () {
        checkCarBrand();
    });

    checkCarBrand();

    function checkCarBrand() {
        let brand = $( "select.markaCarSelect" ).val();

        if (brand == 59) {
            $(".plus-package-input").prop( "disabled", true );
            $(".full-package-input").prop( "disabled", true );
            $('.basic-package-input').prop('checked', true);

        } else {
            $(".plus-package-input").prop( "disabled", false );
            $(".full-package-input").prop( "disabled", false );
        }
    }

    $("#corporationResult").validate({
        rules: {
            corpClientName: {
                required: true
            },
            corpClientLastName: {
                required: true
            },
            corpCEmail: {
                required: true,
                email:true
            },
            corpClientPhone: {
                required: true,
                number: true,
                maxlength:10
            },
            corpClientTK: {
                required: true
            },
            corpClienτΤΜ: {
                required: true,
                number:true
            },
            corpClientYear: {
                required: true,
                number: true
            },
            corpCApart: {
                required: true,
                number: true
            },
            corpKindOf: {
                required: true
            },
            corpKindOfSkeleton: {
                required:true
            },
            corpkalipsiperiexomeno: {
                required:true
            },
            kalipsiseirmo: {
                required:true
            },
            termsvalidateCorp: { required: true }
        },
        errorClass: "invalid",
        validClass: "success"
    });
});
