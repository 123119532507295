import $ from "jquery";
import { CountUp } from 'countup.js';

function counter(){

    var customersNumber = $('#customers').attr('data-total');
    var customersStep = $('#customers').attr('data-step');
    var insuranceNumber = $('#insurances').attr('data-total');
    var insuranceStep = $('#insurances').attr('data-step');

    const customerCountUp = new CountUp('customers', customersNumber,{duration:customersStep});
    const insuranceCountUp = new CountUp('insurances', insuranceNumber,{duration:insuranceStep});

    customerCountUp.start();
    insuranceCountUp.start();
}
counter();


export function changeModelCarSelect() {
    var markaId = $("select.markaCarSelect").val();
    var monteloId = $("select.modelCarSelect").val();
    var token = $("input[name=_token]").val();
    var construction_year = $("#construction_year").val();
    let cc = $('#cubic_capacity').val();
    let plates = $('#license_plate').val();

    if (!markaId || !monteloId || !construction_year || !cc || !plates)
        return;

    $.ajax({
        type: 'POST',
        url: '/requests/engines',
        data: {
            brand_id: markaId,
            model_id: monteloId,
            const_year: construction_year,
            cc: cc,
            usage: 83, // car usage ID
            _token: token
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (result) {
            var engines = [];
            Object.keys(result).forEach(function (key) {
                var engine = result[key];
                engines[$.trim(engine.id)] = engine.name;
            });
            deserializeEngines(engines);
            //code
        },
        complete: function (data) {
            var loaderCompleteT = $(".preloaderSliderAjaxSecond");
            loaderCompleteT.removeClass('preloaderSliderAjaxSecond');
            var loaderDotsComplete = $('.lds-ellipsis');
            loaderDotsComplete.removeClass("lds-ellipsis");
        },
        error: function (response) {
            // TODO pop a message
            $(".carInsurance").append(
                `<div class="preloaderSliderAjax extraSliderAjax sliderAjax">
                        <div class="">
                            <h3>Παρακαλώ προσπαθήστε ξανά.</h3>
                        </div>
                    </div>`
            );
        }
    });
}

function deserializeEngines(engines) {
    var stringifyOptions = '<option disabled selected>Τύπος/Κυβικά αυτοκινήτου</option>';

    engines.forEach(function (name, key) {
        if (selected_engine && selected_engine == key) {
            stringifyOptions = stringifyOptions + "<option";
            stringifyOptions = stringifyOptions + " value='" + key + "' selected>" + name + "</option>";
        } else {
            stringifyOptions = stringifyOptions + "<option";
            stringifyOptions = stringifyOptions + " value='" + key + "'>" + name + "</option>";
        }
    });

    $(".kivismosCarSelect").html(stringifyOptions);
}

$(document).ready(function () {
    // booleans for index Forms
    var isCarFormOk = false;
    var isMotorFormOk = false;
    var isTruckFormOk = false;
    var isMortageOk = false;
    var isForeignerFormOk = false;

    //asfalisi autokinhtou
    $('.markaCarSelect').on('change', '', function () {
        var markaId = $( "select.markaCarSelect" ).val();
        var token = $( "input[name=_token]" ).val();
        $.ajax({
            type: 'POST',
            url: '/requests/models',
            data: {
                brand_id: markaId,
                usage: 83, // car usage ID
                _token: token
            },
            beforeSend: function () {
                $('.carInsurance').append(
                    `<div class="preloaderSliderAjax">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>`
                );
                $(".bgForm").append(
                    `<div class="preloaderSliderAjaxSecond">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>`
                );
            },
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (result) {
                var models = [];
                result.forEach(function (model, index) {
                    models[model.id] = model.name;
                });
                deserializeModels(models);
                //code
            },
            complete: function (data) {
                var loaderComplete = $(".preloaderSliderAjax");
                loaderComplete.removeClass('preloaderSliderAjax');
                var loaderCompleteT = $(".preloaderSliderAjaxSecond");
                loaderCompleteT.removeClass('preloaderSliderAjaxSecond');
                var loaderDotsComplete = $('.lds-ellipsis');
                loaderDotsComplete.removeClass("lds-ellipsis");
            },
            error: function (response) {
                // TODO pop a message
                $(".carInsurance").append(
                    `<div class="preloaderSliderAjax extraSliderAjax sliderAjax">
                        <div class="">
                            <h3>Παρακαλώ προσπαθήστε ξανά.</h3>
                        </div>
                    </div>`
                );
            }
        });
    });


    function deserializeModels(models)
    {
        var stringifyOptions = '<option disabled selected>Μοντέλο αυτοκινήτου</option>';

        models.forEach(function(name, key){
            stringifyOptions = stringifyOptions +    "<option";
            stringifyOptions = stringifyOptions + " value='" + key + "'>" + name + "</option>";
        });

        $( ".modelCarSelect" ).html( stringifyOptions);
    }

    /* Τρέξε μονο στη σελίδα της φόρμας */
    if (window.location.href.indexOf("request") !== -1)
    {
        if (selected_usage == 83) {
            changeModelCarSelect();
        }

        $('.modelCarSelect').on('change', '', function () {
            changeModelCarSelect();
        });
    }

    $(".validateCar").keyup(function () {
        validateCarYear();
    });

    function validateCarYear() {
        var carYearValue = $(".validateCar").val();
        var isNumeric = $.isNumeric($(".validateCar").val());
        var useDate = new Date();
        var todaysYear = useDate.getFullYear();

        if (isNumeric && carYearValue >= 1800 && carYearValue <= todaysYear) {
            $("#validateRepsonse").text(" ");
            $("#validateRepsonse").text(" ");
            isCarFormOk = true;
        }
        else if (!isNumeric) {
            $("#validateRepsonse").text("Παρακαλώ συμπληρώστε το έτος κατασκευής π.χ. 2009");
            isCarFormOk = false;
        }
        else if (carYearValue > todaysYear) {
            $("#validateRepsonse").text("Παρακαλώ πληκτρολογήστε ημερομηνία εώς σήμερα");
            isCarFormOk = false;
        }
        else {
            $("#validateRepsonse").text("Η χρονολογία θα πρέπει να είναι μεγαλύτερη του 1800");
            isCarFormOk = false;
        }
    }

    $(document).on('submit', '#carFormGeneral', function (event) {
        if (isCarFormOk == false || $("select.modelCarSelect").val() == null || $("select.markaCarSelect").val() == null) {
            event.preventDefault();
        }
    });
    // .asfalisi autokinhtou

    //asfalisi mixanis
    $(".validateMotorYear").keyup(function () {
        validateMotorYear();
    });

    function validateMotorYear() {
        var motorYearValue = $(".validateMotorYear").val();
        var isNumeric = $.isNumeric($(".validateMotorYear").val());
        var useDate = new Date();
        var todaysYear = useDate.getFullYear();

        if (isNumeric && motorYearValue >= 1800 && motorYearValue <= todaysYear) {
            $("#validateMotorRepsonse").text(" ");
            isMotorFormOk = true;
        }
        else if (!isNumeric) {
            $("#validateMotorRepsonse").text("Παρακαλώ συμπληρώστε το έτος κατασκευής π.χ. 2009");
            isMotorFormOk = false;
        }
        else if (motorYearValue > todaysYear) {
            $("#validateMotorRepsonse").text("Παρακαλώ πληκτρολογήστε ημερομηνία εώς σήμερα");
            isMotorFormOk = false;
        }
        else {
            $("#validateMotorRepsonse").text("Η χρονολογία θα πρέπει να είναι μεγαλύτερη του 1800");
            isMotorFormOk = false;
        }
    }

    $(document).on('submit', '#motorFormGeneral', function (event) {
        if (isMotorFormOk == false || $("select.markaMotorSelect").val() == null || $(".modelMotorSelect").val() == null) {
            event.preventDefault();
        }
    });
    // .asfalisi mixanis

    //asfalsi fortigoy
    $(".validateTruckYear").keyup(function () {
        validateTruckYear();
    });

    function validateTruckYear() {
        var motorTruckValue = $(".validateTruckYear").val();
        var isNumeric = $.isNumeric($(".validateTruckYear").val());
        var useDate = new Date();
        var todaysYear = useDate.getFullYear();

        if (isNumeric && motorTruckValue >= 1800 && motorTruckValue <= todaysYear) {
            $("#validateTruckResponse").text(" ");
            isTruckFormOk = true;
        }
        else if (!isNumeric) {
            $("#validateTruckResponse").text("Παρακαλώ συμπληρώστε το έτος κατασκευής π.χ. 2009");
            isTruckFormOk = false;
        }
        else if (motorTruckValue > todaysYear) {
            $("#validateTruckResponse").text("Παρακαλώ πληκτρολογήστε ημερομηνία εώς σήμερα");
            isTruckFormOk = false;
        }
        else {
            $("#validateTruckResponse").text("Η χρονολογία θα πρέπει να είναι μεγαλύτερη του 1800");
            isTruckFormOk = false;
        }
    }

    $(document).on('submit', '#truckFormGeneral', function (event) {
        if (isTruckFormOk == false || $("select.markaTruckSelect").val() == null || $(".modelTruckSelect").val() == null) {
            event.preventDefault();
        }
    });
    // .asfalisi fortigou

    $('.money').mask('000.000.000.000 €', {
        alias: 'numeric',
        reverse: true
    });

    //asfalisi katoikias
    $(".jsHouseIns").keyup(function () {
        var mortageHeight = this.value.replace(/[^0-9.]/g, '').replace(/\./g,'');
        console.log(mortageHeight);
        var mortageMin = "22845";
        var minimumMortageHeight = parseFloat(mortageMin);

        if (!$.isNumeric(mortageHeight)) {
            $(".giveMortage").text(" ");
            $(".jsHouseIns").css("borderColor","#f10b0b");
            isMortageOk = false;

        }
        else if (mortageHeight <= minimumMortageHeight) {
            $(".giveMortage").text(" ");
            $(".jsHouseIns").css("borderColor", "#f10b0b");
            isMortageOk = false;
        }
        else {
            isMortageOk = true;
            var storeSum = mortageHeight * 0.000875;
            var storeSumToFixed = storeSum.toFixed(2);
            $(".giveMortage").text(storeSumToFixed + " €");
            $(".jsHouseIns").css("borderColor", "#ef5630");
        }
        $('.giveMortage').attr('data-mortage-price', storeSum);



    });

    $(document).on('submit', '.jsHouseInsbtn', function (event) {
        event.preventDefault();

        if (isMortageOk != false) {
            var order_data = {
                type: "house",
                company_id: "0",
                gross: $('.giveMortage').data('mortage-price'),
                net: $('.giveMortage').data('mortage-price')
            };

            return axios
                .post('/requests/order',
                    JSON.stringify(order_data),
                    {headers: {'Content-Type': 'application/json', timeout: 45000}})
                .then(response => {
                    let order_id = response.data;

                    window.location.href = "/insurance/checkout/"+order_id;

                });
        }
    });
    // .asfalisi katoikias


    //asfalisi allodapou
    $(document).on('click', '.foreigner input', function (event) {

        var val1Cost = 70.00;
        var val2Cost = 90.00;
        var val3Cost = 130.00;
        var finalVal = 0;

        var inputChecked = $(".foreigner input:checked").val();

        if (inputChecked == 1) {
            $(".foreignerResponse").text("ΣΥΝΟΛΟ: " + val1Cost + " €");
            finalVal = val1Cost;
            isForeignerFormOk = true;
        }
        else if (inputChecked == 2) {
            $(".foreignerResponse").text("ΣΥΝΟΛΟ: " + val2Cost + " €");
            finalVal = val2Cost;
            isForeignerFormOk = true;
        }
        else if (inputChecked == 3) {
            $(".foreignerResponse").text("ΣΥΝΟΛΟ: " + val3Cost + " €");
            finalVal = val3Cost;
            isForeignerFormOk = true;
        }
        else if (inputChecked == 4) {
            $(".foreignerResponse").text("ΣΥΝΟΛΟ: " + val4Cost + " €");
            finalVal = val4Cost;
            isForeignerFormOk = true;
        }
        else if (inputChecked == 5) {
            $(".foreignerResponse").text("ΣΥΝΟΛΟ: " + val5Cost + " €");
            finalVal = val5Cost;
            isForeignerFormOk = true;
        }
        else if (inputChecked == 6) {
            $(".foreignerResponse").text("ΣΥΝΟΛΟ: " + val6Cost + " €");
            finalVal = val6Cost;
            isForeignerFormOk = true;
        } else {
            isForeignerFormOk = false;
            finalVal = 0;
        }

        $('.foreignerResponse').attr('data-foreigner-price', finalVal);

    });

    $(document).on('submit', '#foreignerFormGeneral', function (event) {
        event.preventDefault();

        if (isForeignerFormOk != false) {

            var order_data = {
                type: "foreigner",
                company_id: "0",
                gross: $('.foreignerResponse').data('foreigner-price'),
                net: $('.foreignerResponse').data('foreigner-price')
            };

            return axios
                .post('/requests/order',
                    JSON.stringify(order_data),
                    {headers: {'Content-Type': 'application/json', timeout: 45000}})
                .then(response => {
                    let order_id = response.data;

                    window.location.href = "/insurance/checkout/"+order_id;

                });
        }
    });
    // .asfalisi allodapou

    // .asfalisi epixeirisis
    var isEmailOk = false;
    var isPhoneOk = false;
    var isAddressOk = false;

    function validateEmail() {
        let email = $("#corpCEmail").val();
        let email2 = $("#corpConfirmEmail").val();

        if (email === '' || email === null || (email != email2)) {
            $("#corpConfirmEmail").removeClass('success').addClass('invalid');
            isEmailOk = false;
        } else {
            $("#corpConfirmEmail").removeClass('invalid').addClass('success');
            isEmailOk = true;
        }

        return isEmailOk;
    }

    $(document).on('submit', '#corporationResult', function (event) {
        event.preventDefault();

        isEmailOk = validateEmail();

        if (isEmailOk != false) {

            var order_data = {
                first_name: $('#corpClientName').val(),
                last_name: $('#corpClientLastName').val(),
                email: $('#corpCEmail').val(),
                phone: $('#corpClientPhone').val(),
                address_2: $('#corpClientTK').val(),
                covers: JSON.stringify($('#corporationResult').serializeArray(), null, 2),
                type: "business",
                company_id: "0",
                gross: "0",
                net: "0"
            };

            return axios
                .post('/requests/order',
                    JSON.stringify(order_data),
                    {headers: {'Content-Type': 'application/json', timeout: 45000}})
                .then(response => {
                    Swal.fire(
                        'Σας ευχαριστούμε για την επικοινωνία',
                        'Σύντομα ένας συνεργάτης μας θα επικοινωνήσει μαζί σας.',
                        'success'
                    );
                    document.getElementById("corporationResult").reset();

                });
        }
    });
    // .asfalisi epixeirisis

    //sync registration date 1st registration date and
    //$(".registrationDate").on("input", function () {
    //    $(".registrationDatesync").val(this.value);
    //});

    $('.select2').select2({
        sorter: function(data) {
            return data.sort(function(a, b) {
                return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
        }
    });




});
