import '../inputmask';
import 'jquery-ui/ui/widgets/autocomplete'
import 'select2';
import 'owl.carousel';
import 'jquery-validation';
import 'gsap';

import '../frontend/easfalistro/insurance/index';
import '../frontend/easfalistro/insurance/request';
import '../frontend/easfalistro/insurance/checkout';
import '../frontend/common';
import '../frontend/easfalistro/insurance/pages';

$(window).on("load", function () {
    $('.lds-ellipsis div').hide();
    $('.preloaderSlider').hide();
});

$(document).ready(function () {
    //var progress = document.getElementById("progressBar");
    ////Pause the animation for 100 so we can animate from 0 to x%
    //setTimeout(
    //    function () {
    //        //progress.style.width = "100%";
    //        // PHP Version:
    //        // progress.style.width = <?php echo round($percentage150,2); ?>+"%";
    //        progress.style.backgroundColor = "#ef5630";
    //    }
    //    , 100);

});

